$(document).ready(function () {
    $("html").niceScroll({scrollspeed: 100, mousescrollstep: 60, cursorwidth: '10px', cursorborder: 'none', cursoropacitymin: 1, zindex: 9999});

    /*mask tel*/
    if (typeof $("input[type=tel]").mask == 'function') {
        var options = {onKeyPress: function (cep) {
                var mask;
                var e = cep.replace(/\D/g, "");
                if (e.length > 10) {
                    mask = "(00)00000-0000";
                } else {
                    mask = "(00)0000-00000";
                }
                $("input[type=tel]").mask(mask, this);
            }};
        $("input[type=tel]").mask('(00)0000-00000', options);
    }

    /*Validação form*/
    if ($(".valida-form").length > 0) {
        $(".valida-form").WP8FormValidation({
            onInputError: function (obj) {
                obj.addClass("marca-erro");
            },
            onInputOk: function (obj) {
                obj.removeClass("marca-erro");
            },
            onFormSubmitError: function () {
                $(".erro").fadeIn();
            }
        });
    }

    /*Mobile*/
    var aux = 0;
    $(".btn-menu-mob").on("click", function () {
        if (aux == 0) {
            $(".btn-menu-mob").css('display', 'none');
            $(".fa-times").css('display', 'block');
            $(".engloba-menu-mobile").fadeIn();

            $(".engloba-tudo").addClass('some-engloba-tudo');

            aux = 1;
        } else {
            $(".btn-menu-mob").css('display', 'none');
            $(".fa-bars").css('display', 'block');
            $(".engloba-menu-mobile").fadeOut();

            $(".engloba-tudo").removeClass('some-engloba-tudo');

            aux = 0;
        }
    });

    /* DONT TOUCH HERE */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

$(window).on("load", function() {
    /*wow plugin*/
    if($(".wow").length > 0) {
        wow = new WOW({mobile: false, offset: 100});
        wow.init();
    }
});