$(window).on("load resize", function(){

    if($("#popup").length > 0) {
        var url = $("#popup").data("url");
        if(url != ""){
            $("#popup").fancybox({
                afterShow: function () {
                    $(".fancybox-image").wrap($("<a />", {
                        href: url,
                        target: "_blank"
                    }));
                }
            }).trigger("click");
        } else {
            $("#popup").fancybox().trigger("click");
        }
    }

	$(".cada-dir-ind").hover(function() {
		$(this).children('.hover-cada-dir-ind').stop().fadeIn();
	}, function() {
		$(this).children('.hover-cada-dir-ind').stop().fadeOut();
	});

	$(".cada-dir-ind").on("click", function(){
		var pos = $(this).index();

		$(".fancybox-individual").fadeIn();
		$(".corpo-fancy-ind").eq(pos).fadeIn();
	});

	$(".close-fancy").on("click", function(){
		$(".fancybox-individual").fadeOut();
		$(".corpo-fancy-ind").fadeOut();
	});  
});
$(window).on('resize', function() {
	$('.hover-cada-dir-ind').fadeOut(1);
	$(".fancybox-individual").fadeOut(1);
	$(".corpo-fancy-ind").fadeOut(1);
});